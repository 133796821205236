<template>
  <form>
    <div class="row">
      <div class="col">
        <user-avatar :user="user" @update="update"></user-avatar>
      </div>
    </div>
    <template v-if="hasStudioSettings">
      <div class="row">
        <div class="col">
          <h5 class="h3 mb-2">{{ getTrans('messages.reg_studio_details') }}</h5>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <base-input
            v-model="studio.name"
            :placeholder="getTrans('messages.studio_name')"
            type="name"
            :label="getTrans('messages.studio_name')"
            id="studioname"
            :error="getError('studio.name')"
          ></base-input>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <base-input
            v-model="studio.email"
            :placeholder="getTrans('messages.reg_studio_email')"
            type="name"
            :label="getTrans('messages.contact_email')"
            id="studioemail"
            :error="getError('studio.email')"
          ></base-input>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <base-input
            v-model="studio.phone"
            :placeholder="getTrans('messages.reg_studio_phone')"
            type="name"
            :label="getTrans('messages.contact_phone')"
            id="studiophone"
            :error="getError('studio.phone')"
          ></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <base-input
            :label="getTrans('messages.country')"
            :error="getError('studio.country_id')"
          >
            <el-select
              v-model="studio.country.id"
              filterable
              :placeholder="getTrans('messages.select_country')"
            >
              <el-option
                v-for="country in getCountries"
                :key="country.id"
                :label="country.name"
                :value="country.id"
                id="country"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
      </div>
    </template>
    <div class="mb-2 ml-1">
      <h5 class="h3">{{ getTrans('messages.account_details') }}</h5>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          v-model="name"
          :placeholder="getTrans('messages.name')"
          type="name"
          :label="getTrans('messages.name')"
          id="name"
          :error="getError('name')"
        ></base-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <base-input
          placeholder="your@email.com"
          v-model="email"
          type="email"
          :label="getTrans('messages.email')"
          id="email"
          :error="getError('email')"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input label="Country" :error="getError('country_id')">
          <el-select
            v-model="country"
            filterable
            :label="getTrans('messages.select_country')"
          >
            <el-option
              v-for="country in getCountries"
              :key="country.id"
              :label="country.name"
              :value="country.id"
              id="country"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-input
          v-model="city"
          :error="getError('city')"
          :label="getTrans('messages.city')"
          id="city"
        ></base-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <!-- eslint-disable-next-line -->
        <base-input placeholder="https://"
          v-model="website"
          :error="getError('old_website')"
          type="url"
          :label="getTrans('messages.website')"
          id="website"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-button type="primary" @click="saveUser">{{
          getTrans('messages.save')
        }}</base-button>
      </div>
    </div>
  </form>
</template>
<script>
import { mapGetters } from 'vuex';
import { ElSelect, ElOption } from 'element-plus';
import UserAvatar from '../../components/UserAvatar.vue';
export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    UserAvatar,
  },
  emits: ['save'],
  props: {
    user: {
      type: Object,
      default: () => {
        return null;
      },
    },
    getError: {
      type: Function,
      default: () => {
        return () => {
          return '';
        };
      },
    },
  },
  data() {
    return {
      country: '',
      name: '',
      email: '',
      city: '',
      website: '',
      studio: {
        country: {},
        performers: {},
      },
      newValues: {},
      performer_estimate: [
        {
          id: 1,
          estimate: '1-10',
        },
        {
          id: 2,
          estimate: '10-25',
        },
        {
          id: 3,
          estimate: '25-50',
        },
        {
          id: 4,
          estimate: '50-100',
        },
        {
          id: 5,
          estimate: '100+',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('Country', ['getCountries']),
    ...mapGetters('User', { userInUse: 'getData', can: 'can' }),
    hasStudioSettings() {
      if (this.can('studio-owner') && this.user.id == this.userInUse.id) {
        return true;
      }
      return false;
    },
  },
  methods: {
    saveUser() {
      Object.assign(this.newValues, {
        name: this.name,
        email: this.email,
        //old_phonenumber: this.phone,
        country_id: this.country,
        city: this.city,
        old_website: this.website,
      });
      if (this.hasStudioSettings) {
        this.newValues.studio = this.studio;
      }

      this.$emit('save', this.newValues);
    },
    update(values) {
      Object.assign(this.newValues, values);
    },
    clearInput() {
      this.name = '';
      this.email = '';
      this.country = '';
      this.city = '';
      this.website = '';
    },
  },
  mounted() {
    this.$store.dispatch('Country/get');
    this.name = this.user.name;
    this.email = this.user.email;
    this.country = this.user.country_id;
    this.city = this.user.city;
    if (this.user?.managedStudio?.data) {
      if (this.user.managedStudio.data.phone) {
        this.studio.phone = this.user.managedStudio.data.phone;
      }
      if (this.user.managedStudio.data.website) {
        this.studio.website = this.user.managedStudio.data.website;
      }
      if (this.user.managedStudio.data.name) {
        this.studio.name = this.user.managedStudio.data.name;
      }
      if (this.user.managedStudio.data.email) {
        this.studio.email = this.user.managedStudio.data.email;
      }
      if (this.user.managedStudio.data.country_id) {
        this.studio.country.id = this.user.managedStudio.data.country_id;
      }
      if (this.user.managedStudio.data.performer_estimate) {
        this.studio.performers.estimate = this.user.managedStudio.data.performer_estimate;
      }
    }
  },
};
</script>
