<template>
    <studio-account v-if="user" @save="save" :user="user" :getError="getError"></studio-account>
</template>
<script>
import StudioAccount from "./Account/StudioAccount.vue"
export default {
    emits:['save'],
    components:{
        StudioAccount,
    },
    props: {
        user: Object,
        type: String,
        getError: Function,
    },
    computed:{
    },
    methods: {
        save() {
            this.$emit('save', ...arguments);
        }
    }
}
</script>